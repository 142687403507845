import { useState } from 'react'
import { useDispatch } from 'react-redux'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import { useGlobalContext } from '../../../contexts/MainContext'
import { laboratoryTemplateService } from '../../../services/laboratory_template_service'
import AutocompleteTemplate from './AutocompleteTemplate'
import LaboratoriesAutocomplete from '../../laboratory/LaboratoriesAutocomplete'
import TableCustom from '@/components/TableCustom'
import { showNotify } from '@/providers/Notify'
import { actionPushTemplate } from '@/store/template'

const CardCustom = ({ children }) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>{children}</CardContent>
    </Card>
  )
}

function TypographyCustom({ text }) {
  return <Typography variant="h6">{text}</Typography>
}

function TemplatesDialog({ open, handleClose }) {
  const { setLoading } = useGlobalContext()
  const [tableLaboratories, setTableLaboratories] = useState([])
  const [laboratory, setLaboratory] = useState(null)
  const [template, setTemplate] = useState(null)
  const [name, setName] = useState('')
  const dispatch = useDispatch()

  const clear = () => {
    setTableLaboratories([])
    setLaboratory(null)
    setTemplate(null)
    setName('')
    return true
  }

  const handlesubmit = event => {
    event.preventDefault()

    if (!name) {
      showNotify.info(
        'Debe seleccionar una plantilla o asignar un nombre a la plantilla.'
      )
      return
    }

    setLoading(true)
    const model = {
      id: template?.id || '',
      name: name.trim(),
      laboratories: tableLaboratories.map(item => ({
        id: item.id,
        product_id: item.product_id
      }))
    }
    laboratoryTemplateService
      .create(model)
      .then(data => {
        dispatch(actionPushTemplate(data))
        showNotify.success('Guardado con exito')
        clear() && handleClose()
      })
      .finally(() => setLoading(false))
  }

  const handleAddLaboratory = () => {
    if (!laboratory) {
      showNotify.info('Debe seleccionar un laboratorio')
      return
    }

    const model = {
      id: '',
      product: laboratory,
      product_id: laboratory.id
    }
    let exist = false
    tableLaboratories.forEach(item => {
      if (item.product_id === model.product_id) exist = true
    })

    if (exist) {
      showNotify.info('Ya esta agregado')
      return
    }

    setTableLaboratories(previous => [...previous, model])
    setLaboratory(null)
  }

  const handleDeleteRow = lab => {
    setTableLaboratories(item =>
      item.filter(elem => elem.product_id !== lab.product_id)
    )
  }

  const handleGetTemplates = id => {
    setLoading(true)
    laboratoryTemplateService
      .getLaboratoryTemplate({ id })
      .then(response => {
        setTableLaboratories(response.template_product)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Dialog maxWidth="md" open={open} onSubmit={handlesubmit} component="form">
      <DialogTitle>Plantillas</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardCustom>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TypographyCustom text="Crear plantilla nueva" />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Nombre de la plantilla"
                    value={name}
                    onChange={event => {
                      setName(event.target.value)
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={9}>
                  <LaboratoriesAutocomplete
                    requiredA={false}
                    value={laboratory}
                    onChangeCallback={value => setLaboratory(value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button onClick={handleAddLaboratory}>Agregar</Button>
                </Grid>
              </Grid>
            </CardCustom>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardCustom>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TypographyCustom text="Editar plantilla existente" />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteTemplate
                    value={template}
                    onChangeCallBack={value => {
                      setTemplate(value)
                      if (value) {
                        setName(value.name)
                        handleGetTemplates(value.id)
                      } else {
                        setName('')
                        setTableLaboratories([])
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="outlined" onClick={clear}>
                    Limpiar formulario
                  </Button>
                </Grid>
              </Grid>
            </CardCustom>
          </Grid>
          <Grid item xs={12}>
            <TableCustom
              rows={tableLaboratories}
              length={tableLaboratories.length}
              columns={[
                {
                  id: 'product',
                  label: 'Laboratorio',
                  render: row => row.product.name
                },
                {
                  id: 'actions',
                  label: 'Acciones',
                  minWidth: '20%',
                  align: 'center',
                  render: row => (
                    <IconButton onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  )
                }
              ]}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cerrar</Button>
        <Button variant="outlined" type="submit">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default TemplatesDialog
