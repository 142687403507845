// import type {} from '@mui/x-date-pickers/themeAugmentation';

export const components = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      fullWidth: true,
      disableElevation: true
    }
  },
  MuiIconButton: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'small'
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      fullWidth: true,
      margin: 'dense',
      size: 'small',
      sx: { wordWrap: 'break-word' },
      InputLabelProps: {
        shrink: true
      },
      autoComplete: 'off'
    }
  },
  MuiTable: {
    defaultProps: {
      size: 'small',
      stickyHeader: true
    }
  },
  MuiTableRow: {
    defaultProps: {
      hover: true
    }
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
      margin: 'dense'
    }
  },
  MuiInputLabel: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiSelect: {
    defaultProps: {
      size: 'small'
    }
  },
  MuiPaper: {
    defaultProps: {
      variant: 'elevation'
    }
  },
  MuiSwitch: {
    defaultProps: {
      color: 'primary'
    }
  },
  MuiFormControlLabel: {
    defaultProps: {
      labelPlacement: 'start'
    }
  },
  // MuiDatePicker: {
  //   defaultProps: {
  //     disableFuture: false,
  //     mask: '__/__/____',
  //     openTo: 'year',
  //     views: ['year', 'month', 'day']
  //   }
  // },
  MuiListItem: {
    defaultProps: {
      dense: true,
      divider: true,
      alignItems: 'center'
    }
  },
  MuiListSubheader: {
    defaultProps: {
      color: 'primary'
    }
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true
    }
  },
  MuiCard: {
    defaultProps: {
      // raised: false,
      variant: 'outlined',
      elevation: 0
    }
  },
  MuiAutocomplete: {
    defaultProps: {
      size: 'small'
    }
  }
}
