import React, { useEffect, useState } from 'react'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import InputAdornment from '@mui/material/InputAdornment'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'

import { showNotify } from '@/providers/Notify'
import { useColors } from '@/hooks'
import {
  AutocompleteICD,
  AutocompleteRecord,
  AutocompleteUnderlying,
  IsSmoke
} from './antecedent'
import { bloodTypes } from '@/helpers'
import { axios } from '@/boot'

function FormHistory({ historyData, setHistoryData, patient }) {
  const [editBlood, setEditBlood] = useState(true)
  const [data, setData] = useState({
    family_record: [],
    surgical_record: [],
    record_underlying_disease: [],
    medicines: [],
    social_record: [],
    allergies: [],
    blood_type: '',
    patient_type: '',
    smokes: '',
    is_smokes: false
  })
  const { setAction, color } = useColors()

  useEffect(() => {
    setData({
      family_record: historyData?.family_record || [],
      surgical_record: historyData.surgical_record || [],
      medicines: historyData.medicines || [],
      record_underlying_disease: historyData.record_underlying_disease,
      social_record: historyData.social_record || [],
      allergies: historyData?.allergies || [],
      blood_type: historyData.blood_type,
      patient_type: historyData.patient_type,
      smokes: historyData.smokes,
      is_smokes: historyData.is_smokes
    })
  }, [historyData])

  const handleSubmit = data => {
    if (patient !== null) {
      const model = {
        patient_id: patient.id,
        ...data
      }
      handleUpdateHistory(model)
    } else showNotify.info('Seleccione un paciente')
  }

  const handleUpdateHistory = model => {
    axios
      .put(`v1/medical-history/${patient.id}`, model)
      .then(({ data }) => {
        setHistoryData({
          id: data.id,
          family_record: data?.family_record || [],
          surgical_record: data?.surgical_record || [],
          medicines: data?.medicines || [],
          record_underlying_disease: data?.record_underlying_disease || [],
          social_record: data?.social_record || [],
          allergies: data?.allergies || [],
          blood_type: data?.blood_type || '',
          patient_type: data?.patient_type || '',
          smokes: data?.smokes || '',
          is_smokes: data?.is_smokes
        })
        setAction('success')
      })
      .catch(() => {
        setAction('error')
      })
  }

  return (
    <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
      <Grid
        container
        justifyContent="space-around"
        spacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={12}>
          <AutocompleteICD
            freeSolo
            label="Antecedente familiares (ICD / CIE)"
            data={data.family_record}
            onChangeCallback={value =>
              handleSubmit({ ...data, family_record: value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteRecord
            freeSolo
            label="Antecedentes quirurgicos"
            data={data.surgical_record}
            onChangeCallback={value =>
              handleSubmit({ ...data, surgical_record: value })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteRecord
            freeSolo
            label="Medicinas"
            data={data.medicines}
            onChangeCallback={value =>
              handleSubmit({ ...data, medicines: value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteRecord
            freeSolo
            label="Antecedentes sociales"
            data={data.social_record}
            onChangeCallback={value =>
              handleSubmit({ ...data, social_record: value })
            }
          />
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <IsSmoke
            onChange={(text, smoke) => {
              handleSubmit({
                ...data,
                smokes: text,
                is_smokes: smoke
              })
            }}
            smokeText={data.smokes}
            is_smoke={data.is_smokes}
            color={color}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteICD
            freeSolo
            label="Alergias (ICD / CIE)"
            data={data.allergies}
            onChangeCallback={value =>
              handleSubmit({ ...data, allergies: value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small">Tipo de sangre</InputLabel>
            <Select
              size="small"
              label="Tipo de sangre"
              value={data.blood_type}
              disabled={editBlood}
              onChange={e =>
                handleSubmit({ ...data, blood_type: e.target.value })
              }
              startAdornment={
                <InputAdornment position="start">
                  {editBlood ? (
                    <IconButton onClick={() => setEditBlood(!editBlood)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setEditBlood(!editBlood)}>
                      <EditIcon fontSize="small" color="success" />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {bloodTypes.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <AutocompleteUnderlying
            label="Enfermedades de base / antecedentes medicos (ICD / CIE)"
            data={data.record_underlying_disease}
            medical_history_id={historyData.id}
            onChangeCallback={value => {
              const refactor = value.map(item => ({
                id: item.id,
                disease: item.disease,
                medical_history_id: item.medical_history_id,
                diagnostic_id: item.diagnostic_id,
                medical_history_detail_id: item.medical_history_detail_id
              }))
              handleSubmit({ ...data, record_underlying_disease: refactor })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            value={data.patient_type}
            onChange={event =>
              handleSubmit({ ...data, patient_type: event.target.value })
            }
          >
            <FormControlLabel
              value="INTERNO"
              control={<Radio />}
              label="Paciente Interno"
            />
            <FormControlLabel
              value="EXTERNO"
              control={<Radio />}
              label="Paciente Externo"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FormHistory
