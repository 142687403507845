import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CssBaseline } from '@mui/material'
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider
} from '@mui/material/styles'

import { components } from './components'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'

export const ThemeProviderCustom = ({ children }) => {
  const dark = useSelector(state => state.darkMode)
  const themeOptions = useMemo(
    () => ({
      palette: palette(dark),
      shape: { borderRadius: 8 },
      shadows,
      typography,
      components
    }),
    [dark]
  )

  const theme = createTheme(themeOptions)
  // theme.components = ComponentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
