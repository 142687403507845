import React from 'react'

import moment from 'moment'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import TreeItem from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import FolderIcon from '@mui/icons-material/Folder'
import { amber } from '@mui/material/colors'

import TableLaboratory from './TableLaboratory'

function LaboratoryTreeView({ data }) {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem
        nodeId={(data?.id || Math.random()) + ''}
        label={
          <Grid container spacing={1} alignItems="end">
            <FolderIcon style={{ color: amber[500], marginLeft: 5 }} />
            <Grid item>
              <Typography variant="subtitle2">
                # {data.laboratory_number}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>Laboratorio</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                Actualizado: {moment(data.updatedAt).format('DD/MM/YYYY HH:mm')}
              </Typography>
            </Grid>
          </Grid>
        }
      >
        <Grid container marginY={1}>
          <Grid item container xs={12} marginBottom={2} direction="column">
            <Grid item>
              <span style={{ fontWeight: 'bold' }}>Descripción: </span>
              <Typography
                variant="body2"
                margin={1}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {data.description}
              </Typography>
              <span style={{ fontWeight: 'bold' }}>Observaciones: </span>
              <Typography
                variant="body2"
                margin={1}
                sx={{ whiteSpace: 'pre-line' }}
              >
                {data.observation}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableLaboratory sample={data.samples || []} />
          </Grid>
        </Grid>
      </TreeItem>
    </TreeView>
  )
}

export default LaboratoryTreeView
