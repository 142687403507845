import axios from '../boot/axios.js'

class LaboratoryTemplateService {
  create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('v1/laboratory-template', data)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }

  getLaboratoryTemplate({ id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`v1/laboratory-template/${id}`)
        .then(response => resolve(response.data))
        .catch(e => reject(e))
    })
  }
}

export const laboratoryTemplateService = new LaboratoryTemplateService()
