export const IMAGENOLOGIA = 'Imagenologia'

export const WITH_QR = ''

export class SocketKeys {
  static session = 'session'
}

export class cashierConstants {
  static otro = 'otro'
  static devolucion = 'devolucion'
  static pagoDoctores = 'pago doctores'

  static validateOther(text = '') {
    return text.toLowerCase().includes(this.otro)
  }

  static validateDevolution(text = '') {
    return text.toLowerCase().includes(this.devolucion)
  }

  static validatePayDoctor(text = '') {
    return text.toLowerCase().includes(this.pagoDoctores)
  }
}
export class referenceValueType {
  static REACTIVO = 'REACTIVO'
  static CONTEO = 'CONTEO'
  static OTRO = 'OTRO'
  static TRASLATE = {
    [this.REACTIVO]: 'Reactivo',
    [this.CONTEO]: 'Conteo',
    [this.OTRO]: 'Otro'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }
}

export class patientType {
  static INTERNO = 'INTERNO'
  static EXTERNO = 'EXTERNO'
  static TRASLATE = {
    [this.INTERNO]: 'Interno',
    [this.EXTERNO]: 'Externo'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }
}

export class imagingType {
  static PENDIENTE = 'PENDIENTE'
  static TERMINADO = 'TERMINADO'
  static ANULADO = 'ANULADO'

  static COLOR_CLASS = {
    [imagingType.ANULADO]: 'error',
    [imagingType.PENDIENTE]: 'secondary',
    [imagingType.TERMINADO]: 'primary'
  }

  static colorClass(state) {
    return this.COLOR_CLASS[state] || undefined
  }

  static TRASLATE = {
    [this.PENDIENTE]: 'Pendiente',
    [this.TERMINADO]: 'Terminado',
    [this.ANULADO]: 'Anulado'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }
}

export class sampleType {
  static ENTREGADO = 'ENTREGADO'
  static PENDIENTE = 'PENDIENTE'
  static PROCESO = 'PROCESO'
  static TERMINADO = 'TERMINADO'
  static ANULADO = 'ANULADO'

  static TRASLATE = {
    [this.ENTREGADO]: 'Entregado',
    [this.PENDIENTE]: 'Pendiente',
    [this.PROCESO]: 'Proceso',
    [this.TERMINADO]: 'Terminado',
    [this.ANULADO]: 'Anulado'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }
}

export class paymentType {
  static ANULADO = 'ANULADO'
  static EFECTIVO = 'EFECTIVO'
  static TRANSFERENCIA = 'TRANSFERENCIA'

  static TRASLATE = {
    [this.ANULADO]: 'Anulado',
    [this.EFECTIVO]: 'Efectivo',
    [this.TRANSFERENCIA]: 'Transferencia'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }
}

export class genderType {
  static MASCULINO = 'MASCULINO'
  static FEMENINO = 'FEMENINO'

  static TRASLATE = {
    [this.MASCULINO]: 'Masculino',
    [this.FEMENINO]: 'Femenino'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }

  static getAll() {
    return [this.MASCULINO, this.FEMENINO]
  }
}

export class stateMeetType {
  static RESERVADO = 'RESERVADO'
  static SIGNOS_VITALES = 'SIGNOS_VITALES'
  static ATENDIDO = 'ATENDIDO'
  static AUSENTE = 'AUSENTE'
  static REPROGRAMADO = 'REPROGRAMADO'
  static ELIMINADO = 'ELIMINADO'

  static TRASLATE = {
    [this.RESERVADO]: 'Reservado',
    [this.SIGNOS_VITALES]: 'Signos vitales realizado',
    [this.ATENDIDO]: 'Atendido',
    [this.AUSENTE]: 'Ausente',
    [this.REPROGRAMADO]: 'Reprogramado',
    [this.ELIMINADO]: 'Eliminado'
  }

  static traslate(state) {
    return this.TRASLATE[state] || ''
  }

  static COLOR_HEX = {
    RESERVADO: '#34ace0',
    SIGNOS_VITALES: '#a29bfe',
    ATENDIDO: '#00b894',
    AUSENTE: '#ff7979',
    REPROGRAMADO: '#9c88ff',
    ELIMINADO: '#ef5350'
  }

  static colorHex(state) {
    return this.COLOR_HEX[state] || undefined
  }
}

export class stateSalesType {
  static TODOS = ''
  static NO_PAGADO = 'NO_PAGADO'
  static PAGADO = 'PAGADO'
  static CREDITO = 'CREDITO'
  static ELIMINADO = 'ELIMINADO'
  static ANULADO = 'ANULADO'

  static TRASLATE = {
    TODOS: 'Todos',
    [this.NO_PAGADO]: 'No pagado',
    [this.PAGADO]: 'Pagado',
    [this.CREDITO]: 'Credito',
    [this.ELIMINADO]: 'Eliminado',
    [this.ANULADO]: 'Anulado'
  }

  static traslate(state) {
    return this.TRASLATE[state] || 'Sin estado'
  }

  static COLOR_HEX = {
    [this.NO_PAGADO]: '#ef5350',
    [this.PAGADO]: '#27ae60',
    [this.CREDITO]: '#03a9f4',
    [this.ELIMINADO]: '#ff7979',
    [this.ANULADO]: '#ff9800'
  }

  static colorHex(state) {
    return this.COLOR_HEX[state] || undefined
  }

  static COLOR_CLASS = {
    [this.NO_PAGADO]: 'error',
    [this.PAGADO]: 'success',
    [this.CREDITO]: 'info',
    [this.ELIMINADO]: 'secondary',
    [this.ANULADO]: 'warning'
  }

  static colorClass(state) {
    return this.COLOR_CLASS[state] || undefined
  }
}

export class stateVascularAccessType {
  static CATETER = 'CATETER'
  static FISTULA = 'FISTULA'

  static VASCULAR_ACCESS_WORDS = {
    [this.CATETER]: 'CATETER VENOSO',
    [this.FISTULA]: 'FISTULA ARTERIO VENOSA'
  }

  static TRASLATE = {
    [this.CATETER]: 'Cateter',
    [this.FISTULA]: 'Fistula'
  }

  static traslate(access) {
    return this.TRASLATE[access]
  }
}

export class rolesType {
  static FARMACIA = 'FARMACIA'
  static ENFERMERA_HEMODIALISIS = 'ENFERMERA_HEMODIALISIS'
  static DOCTOR_HEMODIALISIS = 'DOCTOR_HEMODIALISIS'
  static IMAGENOLOGIA = 'IMAGENOLOGIA'
  static ENFERMERA = 'ENFERMERA'
  static BIOQUIMICA = 'BIOQUIMICA'
  static DOCTOR = 'DOCTOR'
  static LABORATORIO = 'LABORATORIO'
  static REGISTRO = 'REGISTRO'
  static CAJA = 'CAJA'
  static PACIENTE = 'PACIENTE'
  static ADMIN = 'ADMIN'
}

export class signatureType {
  static FIRMA = 'FIRMA'
  static SELLO = 'SELLO'
}

export class searchParamNames {
  static patientId = 'patientId'
  static saleId = 'saleId'
  static sessionId = 'sessionId'
  static turnId = 'turnId'
  static imagingDetailId = 'imagingDetailId'
  static from = 'from'
  static to = 'to'
  static search = 'search'
  static id = 'id'
  static diagnostic = 'diagnostic'
}

export class whatsAppType {
  static all = ''
  static onlyUsers = 'onlyUsers'
  static onlyGroups = 'onlyGroups'
  static chat = 'chat'
  static grupo = 'grupo'
}
