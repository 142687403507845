import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import { icdService } from 'services/search_icd_service'

function AutocompleteUnderlying({
  onChangeCallback,
  data,
  label,
  medical_history_id
}) {
  const [icd10, setIcd10] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [value, setValue] = React.useState([])

  React.useEffect(() => {
    if (data) setValue([...data])
  }, [data])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search !== '' && search && search.length > 2) {
        setLoading(true)
        icdService
          .getSearchUnderlying(search, medical_history_id)
          .then(response => setIcd10(response))
          .finally(() => setLoading(false))
      } else clearTimeout(delayDebounceFn)
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  return (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      loading={loading}
      loadingText="Cargando..."
      noOptionsText="Sin resultados"
      size="small"
      value={value}
      options={icd10}
      getOptionLabel={option => option.disease}
      isOptionEqualToValue={(option, value) => option.disease === value.disease}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} arrow title={option.disease} enterDelay={1000}>
            <Chip
              size="small"
              label={option?.disease}
              style={{ textOverflow: 'ellipsis', maxWidth: '300px' }}
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          onChange={e => {
            if (e.target.value !== '') setSearch(e.target.value)
            else setIcd10([])
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} sx={{ mr: 3 }} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
        />
      )}
      onChange={(_, value) => {
        setValue(value)
        onChangeCallback(value)
      }}
    />
  )
}

export default AutocompleteUnderlying
