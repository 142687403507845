import Grid from '@mui/material/Grid'

import { column1, column2, column3, column4 } from '@/helpers'
import LaboratoryRow from '@/components/cashier/laboratory-list/LaboratoryRow'

const LaboratoryList = ({
  laboratories,
  setLaboratories,
  total,
  setTotal,
  disablePrice
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        {Object.keys(laboratories).length > 0 &&
          column1?.map((name, index) => (
            <LaboratoryRow
              key={index}
              name={name}
              state={laboratories}
              setState={setLaboratories}
              total={total}
              setTotal={setTotal}
              disablePrice={disablePrice}
            />
          ))}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {Object.keys(laboratories).length > 0 &&
          column2?.map((name, index) => (
            <LaboratoryRow
              key={index}
              name={name}
              state={laboratories}
              setState={setLaboratories}
              total={total}
              setTotal={setTotal}
              disablePrice={disablePrice}
            />
          ))}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {Object.keys(laboratories).length > 0 &&
          column3?.map((name, index) => (
            <LaboratoryRow
              key={index}
              name={name}
              state={laboratories}
              setState={setLaboratories}
              total={total}
              setTotal={setTotal}
              disablePrice={disablePrice}
            />
          ))}
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {Object.keys(laboratories).length > 0 &&
          column4?.map((name, index) => (
            <LaboratoryRow
              key={index}
              name={name}
              state={laboratories}
              setState={setLaboratories}
              total={total}
              setTotal={setTotal}
              disablePrice={disablePrice}
            />
          ))}
      </Grid>
    </Grid>
  )
}

export default LaboratoryList
