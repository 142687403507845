import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import { useState, useEffect } from 'react'

export const IsSmoke = ({ onChange, smokeText, is_smoke, color }) => {
  const [smoke, setSmoke] = useState('0')
  const [text, setText] = useState('')

  useEffect(() => {
    setSmoke(is_smoke ? '1' : '0')
    if (smokeText !== '') setText(smokeText)
  }, [is_smoke])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (text !== smokeText) onChange(text, smoke === '1')
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [text])

  const handleChange = e => {
    setSmoke(e.target.value)
    if (e.target.value === '0') {
      setText('')
      onChange(null, e.target.value === '1')
    } else {
      onChange(text, e.target.value === '1')
    }
  }
  return (
    <>
      <FormControl fullWidth sx={{ justifyContent: 'start' }}>
        <FormLabel>¿El paciente fuma?</FormLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={smoke === '0'}
            onChange={handleChange}
            value="0"
            sx={{ bgcolor: color }}
          />
          <Typography>No, fuma</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            checked={smoke === '1'}
            onChange={handleChange}
            value="1"
            sx={{ bgcolor: color }}
          />
          <Typography>Si, fuma</Typography>
        </div>
        {smoke === '1' && (
          <OutlinedInput
            size="small"
            sx={{ bgcolor: color }}
            placeholder="Descripción"
            fullWidth
            multiline
            value={text}
            onChange={e => setText(e.target.value)}
          />
        )}
      </FormControl>
    </>
  )
}
