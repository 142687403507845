import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'

import LoadingPage from '../Loading'
import { PrivateRouteList } from '@/routers'

const Emergency = lazy(() =>
  import('./views').then(module => ({ default: module.Emergency }))
)
const AdmissionFormDetail = lazy(() =>
  import('./views').then(module => ({ default: module.AdmissionFormDetail }))
)

export const routerEmergency = (
  <Route
    path={PrivateRouteList.EMERGENCY.ROOT}
    // element={<div>layout dennis</div>}
  >
    <Route
      index
      element={
        <Suspense fallback={<LoadingPage />}>
          <Emergency />
        </Suspense>
      }
    />

    <Route path={PrivateRouteList.EMERGENCY.ADMISSION}>
      <Route
        path={PrivateRouteList.EMERGENCY.ADMISSION_ID}
        element={
          <Suspense fallback={<LoadingPage />}>
            <AdmissionFormDetail />
          </Suspense>
        }
      />
    </Route>
  </Route>
)
