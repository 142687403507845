import { Grid, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

function RightMenu({ setDiagnostic, diagnostic = '' }) {
  const [diag, setDiag] = useState('')
  useEffect(() => {
    setDiag(diagnostic)
  }, [diagnostic])

  return (
    <Grid item>
      <TextField
        multiline
        minRows={3}
        label="Diagnostico para impresión"
        value={diag}
        onChange={e => setDiag(e.target.value)}
        onBlur={() => setDiagnostic(diag)}
      />
    </Grid>
  )
}

export default RightMenu
