import '@/components/init'

import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import esLocale from 'date-fns/locale/es'

import { ThemeProviderCustom } from './theme'
import { AppRouters } from './routers/AppRouter'
import WraperUtilities from './hoc/WraperUtilities'
import store from './store'

import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/material.css'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProviderCustom>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={esLocale}
          >
            <WraperUtilities>
              <AppRouters />
            </WraperUtilities>
          </LocalizationProvider>
          <ToastContainer
            draggable
            pauseOnHover
            limit={3}
            autoClose={3000}
            theme="colored"
            position="top-center"
          />
        </ThemeProviderCustom>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
