import { memo, useEffect, useState } from 'react'
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams
} from 'react-router-dom'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import SearchPatientAutocomplete from '@/components/SearchPatientAutocomplete'
import { userService } from '@/services'
import { PrivateRouteList } from '@/routers/routerList'
import { searchParamNames } from '@/constants'

const TabCustom = memo(({ label, to, i, value, setValue }) => {
  const location = useLocation()
  const isActive = location.pathname
    .split('/')
    .find(value => value.match(to.replace('/', '')))
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (isActive) {
      setValue(i.toString())
    }
  })

  return (
    <Tab
      label={label}
      value={value}
      onClick={() => {
        if (location.pathname.split('/').at(-1).includes(to.replace('/', ''))) {
          return
        }
        if (searchParams.get(searchParamNames.patientId)) {
          navigate({
            pathname: to,
            search: new URLSearchParams({
              [searchParamNames.patientId]: searchParams.get(
                searchParamNames.patientId
              )
            }).toString()
          })
        } else {
          navigate({ pathname: to })
        }
        setValue(i.toString())
      }}
    />
  )
})
export default function SecondaryLayout({ rutas }) {
  const [value, setValue] = useState('0')
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState(null)
  const { pathname } = useLocation()

  const handleChangeTab = (_, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (searchParams.get(searchParamNames.patientId)) {
      userService
        .getUser(searchParams.get(searchParamNames.patientId))
        .then(data => setSearch(data))
    }
  }, [searchParams.get(searchParamNames.patientId)])

  return (
    <>
      <Grid container justifyContent="center" my={2}>
        <Grid item xs={12} md={6}>
          <SearchPatientAutocomplete
            readOnly={[
              PrivateRouteList.HEMODIALYSIS.FORM,
              PrivateRouteList.HEMODIALYSIS.SESSION_PDF
            ].includes(pathname.split('/').at(-1))}
            value={search}
            onChangeCallBack={value => {
              setSearch(value)
              if (value) {
                setSearchParams({ [searchParamNames.patientId]: value.id })
              } else {
                setSearchParams()
              }
            }}
          />
        </Grid>
      </Grid>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        variant="scrollable"
        component={Card}
        sx={theme => ({
          mb: 2,
          color: theme.palette.mode === 'dark' ? 'white' : 'black'
        })}
      >
        {rutas.map((item, i) => (
          <TabCustom
            key={item.label}
            i={i}
            setValue={setValue}
            to={item.to}
            label={item.label}
            value={i.toString()}
          />
        ))}
      </Tabs>

      <Outlet />
    </>
  )
}
