import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/Warning'
import ButtonShowFile from '../../ButtonShowFile'

const formatRows = item => {
  if (
    (!item.result &&
      item.result === '' &&
      (item.within_range !== '' || item.within_range)) ||
    item.out_of_range !== '' ||
    item.out_of_range
  ) {
    return (
      <>
        <TableCell
          align="center"
          sx={{
            color: 'green',
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {item?.within_range || ''}
        </TableCell>
        <TableCell align="center" sx={{ color: 'red' }}>
          {item?.out_of_range || ''}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {formatReference(item.reference_value)}
        </TableCell>
      </>
    )
  }
  if (
    item.result !== '' &&
    item.result &&
    !item.within_range &&
    !item.out_of_range &&
    !item.reference_value
  ) {
    return (
      <TableCell
        colSpan={3}
        sx={{
          borderLeft: 1,
          borderRight: 1,
          borderColor: 'divider'
        }}
      >
        {item?.result || ''}
      </TableCell>
    )
  }
  if (
    item.result !== '' &&
    item.result &&
    !item.within_range &&
    !item.out_of_range &&
    item.reference_value
  ) {
    return (
      <>
        <TableCell
          colSpan={2}
          sx={{
            borderLeft: 1,
            borderColor: 'divider'
          }}
        >
          {item.result}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider'
          }}
        >
          {formatReference(item.reference_value)} C
        </TableCell>
      </>
    )
  }
  return <TableCell colSpan={3} />
}
const formatReference = reference => {
  if (reference) {
    return `${reference.value_reference} ${reference.unit_measurement}`
  }
  return ''
}

function TableLaboratory({ sample }) {
  return (
    <TableContainer sx={{ border: 1, borderColor: 'divider' }}>
      <Table sx={{ width: '100%' }} size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell scope="row">Nombre</TableCell>
            <TableCell
              align="center"
              sx={{
                borderLeft: 1,
                borderRight: 1,
                borderColor: 'divider'
              }}
            >
              Dentro de Rango
            </TableCell>
            <TableCell align="center">Fuera de Rango</TableCell>
            <TableCell
              align="center"
              sx={{
                borderLeft: 1,
                borderRight: 1,
                borderColor: 'divider'
              }}
            >
              Referencia
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sample?.map((item, index) => (
            <React.Fragment key={index}>
              <TableRow hover selected>
                <TableCell colSpan={5} scope="row">
                  <Typography
                    variant="caption"
                    sx={{
                      mr: 1,
                      fontWeight: 'bold',
                      textTransform: 'uppercase'
                    }}
                  >
                    {item.group}
                  </Typography>
                </TableCell>
              </TableRow>
              {item.sample_result.map((elem, i) => (
                <TableRow hover key={i}>
                  <TableCell>{elem.name}</TableCell>
                  {formatRows(elem)}
                  <TableCell align="center">
                    {elem.danger && (
                      <Tooltip title="Resultado peligroso" arrow>
                        <IconButton size="small">
                          <WarningIcon sx={{ color: 'red' }} fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {elem.attached !== null && elem.attached !== '' && (
                      <ButtonShowFile url={elem.attached} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableLaboratory
