export const PrivateRouteList = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  ACCCESS_DENIED: '/access-denied',
  REGISTER: '/register',
  SCHEDULE: '/schedule',
  CASHIER: {
    ROOT: '/cashier',
    CALENDAR: '/calendar',
    RESCHEDULE: '/reschedule',
    CREDIT_SALE: '/credit-sale',
    CREDIT_SALE_DETAIL: '/credit-sale-detail',
    REPORT: '/report',
    REPORT_PRINT: '/report-print',

    LABS: 'template-laboratories',
    SALE_PRINT: 'sale-print'
  },
  DAY_OFF: '/day-off',
  PROFILE: '/profile',
  SAMPLES: '/samples',
  VITAL_SIGNS: '/vital-signs',
  LABORATORY: {
    ROOT: '/laboratory',
    HISTORY: 'history',
    REFERENCE_VALUE: 'reference-value',
    SUB_LABORATORY: 'sub-laboratories',
    ANALYSIS: 'analysis',
    WHATSAPP: 'messages'
  },
  MEDICAL_HISTORY: {
    ROOT: '/medical-history',
    MEDICAL_HISTORY_SALES: '/medical-history-sales',
    SPECIALTY: '/specialty',

    HISTORY: 'histories',
    LABORATORY: 'laboratories',
    IMAGENOLOGY: 'imaging',
    COMPARATIVE_LABORATORIES: 'comparative-laboratory',
    CERTIFICATE: 'certificate'
  },
  SALE_LABORATORY: '/sale-laboratory',
  IMAGEN: { ROOT: '/imaging', IMAGEN_DETAIL: 'detail' },
  SETTINGS: '/settings',
  HEMODIALYSIS: {
    ROOT: '/hemodialysis',
    TURNS: '/hemodialysis-turns',

    SESSIONS: 'sessions',
    PATIENTS: 'patients',
    SESSION_PDF: 'session-pdf',
    FORM: 'session-form',
    CLINICAL_REPORT: 'clinical-report',
    MEDICINES: 'medicines',
    LABORATORIES: 'laboratories',
    QUICK_VIEW_LABORATORIES: 'quick-view-laboratory',
    IMAGENOLOGY: 'imaging'
  },
  PHARMACY: '/pharmacy',
  EMERGENCY: {
    ROOT: '/emergency',
    ADMISSION: 'admission',
    ADMISSION_ID: ':admissionId'
  }
}

export const PublicRoutesList = {
  LOGIN: '/login'
}
