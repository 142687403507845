import React from 'react'

import HistoryTimelineItem from '../../../pages/medical_history/components/HistoryTimelineItem'
import LaboratoryTreeView from './LaboratoryTreeView'

function TimeLineLaboratory({ data }) {
  return (
    <HistoryTimelineItem date={data.createdAt}>
      <LaboratoryTreeView data={data} />
    </HistoryTimelineItem>
  )
}
export default TimeLineLaboratory
