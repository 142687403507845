import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import ReferPDF from './ReferPDF'
import { DialogCustom } from '@/components/DialogCustom'

function ReferPatient({ open, handleClose, diagnostic, presc, patient }) {
  const [prescription, setPrescription] = useState('')
  const [diag, setDiagnostic] = useState('')
  const [accept, setAccept] = useState({
    Rx: presc || '',
    Dx: diagnostic || ''
  })

  useEffect(() => {
    if (diagnostic && diagnostic !== '') setDiagnostic(diagnostic)
    if (presc && presc !== '') setPrescription(presc)
  }, [diagnostic, presc])

  return (
    <DialogCustom
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      title="Derivar paciente"
    >
      <Grid container spacing={2} direction="row" p={2}>
        <Grid item xs={12} md={8}>
          <ReferPDF
            patient={patient}
            prescription={accept.Rx}
            diagnostic={accept.Dx}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} justifyContent="space-around">
            <Grid item xs={12}>
              <TextField
                multiline
                label="Historial"
                value={prescription}
                onChange={e => setPrescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                label="Dx"
                value={diag}
                onChange={e => setDiagnostic(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  setAccept({
                    Rx: prescription,
                    Dx: diag
                  })
                }
              >
                Actualizar
              </Button>
            </Grid>
            <Grid item xs={12} md={5}>
              <Button variant="contained" onClick={handleClose}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogCustom>
  )
}

export default ReferPatient
