import React from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { useSelector } from 'react-redux'

function AutocompleteTemplate({ value, onChangeCallBack }) {
  const template = useSelector(state => state.reducerTemplate)

  return (
    <Autocomplete
      noOptionsText="Sin sugerencias"
      size="small"
      value={value}
      options={template}
      autoHighlight
      isOptionEqualToValue={option => option.id === value.id}
      getOptionLabel={template => template.name}
      renderOption={(props, template) => (
        <Box component="li" {...props}>
          {template.name}
        </Box>
      )}
      renderInput={params => <TextField {...params} fullWidth label="Buscar" />}
      onChange={(e, value) => {
        onChangeCallBack(value)
      }}
    />
  )
}

export default AutocompleteTemplate
