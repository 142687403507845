import React from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

function AutocompleteRecord({ onChangeCallback, data, label, ...others }) {
  const [value, setValue] = React.useState([])

  React.useEffect(() => {
    if (data) setValue([...data])
  }, [data])

  return (
    <Autocomplete
      {...others}
      multiple
      disableClearable
      noOptionsText=""
      filterSelectedOptions
      size="small"
      value={value}
      options={[]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} arrow title={option} enterDelay={1000}>
            <Chip
              size="small"
              label={option}
              style={{ textOverflow: 'ellipsis', maxWidth: '250px' }}
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={label}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
        />
      )}
      onChange={(_, value) => {
        setValue(value)
        onChangeCallback(value)
      }}
    />
  )
}

export default AutocompleteRecord
