import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { useGlobalContext } from '@/contexts/MainContext'
import { authService } from '@/services/auth_service'
import { cleanLocalStorage } from '@/utils'
import { showNotify } from '@/providers/Notify'
import { logIn, logOut } from '@/store/auth'

function DialogSesion({ open, handleClose }) {
  const dispatch = useDispatch()
  const { setLoading } = useGlobalContext()
  const { user } = useSelector(state => state.auth)

  const [showPassword, setShowPassword] = useState(false)
  const [data, setLogIn] = useState({
    email: '',
    password: ''
  })

  const handleSubmit = event => {
    event.preventDefault()
    if (data.email === user.email) {
      setLoading(true)
      authService
        .login(data)
        .then(response => {
          dispatch(logIn(response))
          handleClose()
        })
        .finally(() => setLoading(false))
    } else {
      showNotify.error('Usuario no valido')
    }
  }

  return (
    <Dialog open={open} fullScreen>
      <DialogContent>
        <Container
          maxWidth="xs"
          sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InfoOutlinedIcon sx={{ fontSize: 100 }} color="warning" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h4">Tu sesión ha expirado</Typography>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  color: 'gray'
                }}
              >
                <Typography variant="body1">
                  Por seguridad hemos finalizado tu sesión por inactividad.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={data.email}
                onChange={event =>
                  setLogIn({ ...data, email: event.target.value })
                }
                required
                label="Correo electronico"
                autoComplete="off"
                type="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={data.password}
                onChange={event =>
                  setLogIn({ ...data, password: event.target.value })
                }
                required
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Continuar trabajando
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handleClose()
                  dispatch(logOut())
                  cleanLocalStorage()
                }}
                variant="contained"
                color="error"
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default DialogSesion
